import formInputFieldMixin from './formInputField';
import formSelectPropsMixin from './formSelectProps';

export default {
  mixins: [formInputFieldMixin, formSelectPropsMixin],
  data () {
    return {
      internalValue: this.value,
    };
  },
  methods: {
    onChange (event) {
      this.$emit('input', this.internalValue);
    },
  },
  watch: {
    value (newValue) {
      this.internalValue = newValue;
    },
  },
};
